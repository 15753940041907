<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-wrap flex-grow-1 justify-end align-center">
        <!-- search input -->
        <v-text-field
          v-model="searchQuery"
          dark
          dense
          outlined
          hide-details
          label="Search Users"
          class="mr-3 mr-md-6"
          style="max-width: 20em"
          append-icon="search"
          @click:append="handlePagination(1)"
          @keypress.enter="handlePagination(1)"
        />

        <v-btn
          depressed
          color="white"
          @click="fetchItems"
        >
          <v-icon color="secondary" left>
            refresh
          </v-icon>

          Refresh
        </v-btn>
      </div>
    </breadcrumbs>

    <template v-if="response">
      <v-data-table
        :headers="tableHeaders"
        :items="response.data"
        :server-items-length="response.total"
        class="mt-4 box-shadow-soft"
        @update:page="handlePagination"
        @update:items-per-page="handlePerPage"
      >
        <template v-slot:item.created_at="{ item }">
          <span
            class="cursor-pointer"
            :title="item.created_at"
          >
            {{ item.created_at_relative }}
          </span>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="shouldShowDeleteDialog = true; selectedItem = item;"
            >
              Delete
            </v-btn>

            <v-btn
              small
              depressed
              color="primary"
              @click="shouldShowDetailsDialog = true; selectedItem = item;"
            >
              View Details
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>

    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          Delete this message?
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            depressed
            class="mr-3"
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shouldShowDetailsDialog"
      max-width="600"
    >
      <v-card v-if="selectedItem">
        <v-card-title>
          Contact Message
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              :value="selectedItem.name"
              class="mb-3"
              type="text"
              label="Name"
              readonly
              outlined
              dense
            ></v-text-field>

            <v-text-field
              :value="selectedItem.company_name"
              class="mb-3"
              type="text"
              label="Company Name"
              readonly
              outlined
              dense
            ></v-text-field>

            <v-text-field
              :value="selectedItem.email"
              class="mb-3"
              type="text"
              label="Email Address"
              readonly
              outlined
              dense
            ></v-text-field>

            <v-textarea
              :value="selectedItem.message"
              label="Message"
              readonly
              outlined
              required
              dense
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            depressed
            color="primary"
            @click="shouldShowDetailsDialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

// Export the SFC
export default {
  // Name of the component
  name: "AdminContactIndex",

  // Register the components
  components: {
    Breadcrumbs
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,
    shouldShowDeleteDialog: false,
    shouldShowDetailsDialog: false,
    selectedItem: null,

    tableHeaders: [
      {
        text: "ID",
        value: "id",
        sortable: false
      },
      {
        text: "Name",
        value: "name",
        sortable: false
      },
      {
        text: "Company Name",
        value: "company_name",
        sortable: false
      },
      {
        text: "Timestamp",
        value: "created_at",
        sortable: false
      },
      {
        text: "Message",
        value: "message",
        sortable: false
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      },
    ]
  }),

  // Define local computable properties
  computed: {
    /**
     * Get the query object from the store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["admin/contacts/query"]
    },

    /**
     * Get the response object from the store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["admin/contacts/response"]
    },

    // Use getter and setter to link the value with vuex store
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // Update the query term
        this.$store.dispatch("admin/contacts/updateQuery", { key: 'search', value })
      }
    }
  },

  // Define local methods
  methods: {
    /**
     * Handle the pagination event
     *
     * @param {Number} value
     */
    handlePagination(value) {
      this.$store.dispatch("admin/contacts/updateQuery", { key: 'page', value })

      this.fetchItems()
    },

    /**
     * Handle the per page event
     *
     * @param {Number} value
     */
    handlePerPage(value) {
      this.$store.dispatch("admin/contacts/perPage", { key: 'page', value })

      this.fetchItems()
    },

    /**
     * Handle the delete event
     *
     * @returns {void}
     */
    async handleDelete() {
      // If making request or no item is selected, return
      if (this.isMakingRequest || !this.selectedItem) return

      // Set the flag to true
      this.isMakingRequest = true

      try {
        // Try making network request
        await axios({
          url: "/api/admin/contacts/" + this.selectedItem.id,
          method: "DELETE"
        })

        this.fetchItems()
      } catch (error) {
        logger({ type: "Delete Contact Message Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        this.isMakingRequest = false
        this.shouldShowDeleteDialog = false
      }
    },

    /**
     * Fetch the items
     *
     * @returns {void}
     */
    async fetchItems() {
      if (this.isMakingRequest) return

      this.isMakingRequest = true
      await this.$store.dispatch("admin/contacts/fetchItems")
      this.isMakingRequest = false
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    this.fetchItems()
  }
}
</script>
